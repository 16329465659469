import { ICenter, ITerm } from '@app/types';
import { IAppState } from 'store';

export const selectAuthState = (state: IAppState) => state.auth;

export const selectIsAuthenticating = (state: IAppState) => selectAuthState(state).isAuthenticating;

export const selectIsAuthenticated = (state: IAppState) => {
  const authState = selectAuthState(state);

  if (!authState.authenticated) {
    return false;
  }

  if (!authState.authInfo) {
    return false;
  }

  return true;
};

export const selectUser = (state: IAppState) => {
  const authState = selectAuthState(state);

  if (!authState.authInfo) {
    return null;
  }

  return authState.authInfo.user;
};

export const selectFranchisor = (state: IAppState) => {
  const authState = selectAuthState(state);

  if (!authState.franchisor) {
    return null;
  }

  return authState.franchisor.data;
};

export const selectCenters = (state: IAppState) => {
  const authState = selectAuthState(state);

  if (!authState.centers) {
    return [];
  }

  return authState.centers.data;
};

export const selectTerms = (state: IAppState) => {
  const authState = selectAuthState(state);

  if (!authState.terms) {
    return [];
  }

  const allTerms = authState.terms.data;
  const selectedCenter = authState.selectedCenter;

  return selectedCenter ? allTerms.filter(x => !x.centerId || x.centerId === selectedCenter.id) : allTerms;
};

export const selectedCenter = (state: IAppState): ICenter => selectAuthState(state).selectedCenter || {} as ICenter;
export const selectedTerm = (state: IAppState): ITerm => selectAuthState(state).selectedTerm || {} as ITerm;
