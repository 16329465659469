import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';

import { changeDataExpireBreakpoint, reloadLanguages } from '@app/actions/root';
import analytics from '@app/helpers/analytics.helper';
import { fetchCenters, fetchCentersSuccess, fetchTerms, fetchTermsSuccess, logout } from 'actions/auth';
import Config from 'config';
import { getExpirableData, getIsDataExpired } from 'helpers/common.helpers';
import httpHelpers from 'helpers/http.helpers';
import { store } from 'store';
import { ICenter, IFetchOptions, ITerm } from 'types';

export function* fetchTermsSaga(action: Action<IFetchOptions>) {
  try {
    const authState = store.getState().auth;
    if (!authState.authenticated) {
      return;
    }

    if (action.payload.forceFetch || getIsDataExpired(authState.terms)) {
      const terms: ITerm[] = yield httpHelpers.get<ITerm[]>(`${Config.SERVER_URL}/api/terms/unpaged`);
      yield put(fetchTermsSuccess(getExpirableData(terms, 1, 'h')));
    }
  } catch (err) {
    //
  }
}

export function* fetchCentersSaga(action: Action<IFetchOptions>) {
  try {
    const authState = store.getState().auth;
    if (!authState.authenticated) {
      return;
    }

    if (action.payload.forceFetch || getIsDataExpired(authState.centers)) {
      const centers: ICenter[] = yield httpHelpers.get<ICenter[]>(`${Config.SERVER_URL}/api/centers/unpaged`);
      yield put(fetchCentersSuccess(getExpirableData(centers, 1, 'm')));
    }
  } catch (err) {
    //
  }
}

export function* logoutSaga() {
  try {
    yield put(changeDataExpireBreakpoint(Date.now()));
    analytics.setUser({ userId: null });
    analytics.event('logout');
    yield put(reloadLanguages());
  } catch (err) {
    //
  }
}

export default [
  takeLatest(fetchTerms.toString(), fetchTermsSaga),
  takeLatest(fetchCenters.toString(), fetchCentersSaga),
  takeLatest(logout.toString(), logoutSaga),
];
