import { reloadLanguages, setGlobalMessageBox } from '@app/actions/root';
import Config from '@app/config';
import analytics from '@app/helpers/analytics.helper';
import history from '@app/helpers/history.helper';
import { store } from '@app/store';
import * as QueryString from 'query-string';
import { Action } from 'redux-actions';
import { put, takeLatest } from 'redux-saga/effects';
import { fetchCenters, fetchTerms, login, loginFail, loginSuccess, setIsAuthenticating } from '../../actions/auth';
import { ILoginParam, ILoginResponse, IUser } from '../../types/common';

const fetchLoginAPI = async (data: ILoginParam): Promise<ILoginResponse> => {
  const franchisor = store.getState().auth.franchisor;
  const response = await fetch(`${Config.AUTH_SERVER_URL}/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Franchisor-Id': franchisor ? String(franchisor.data.id) : '',
    },
    body: `grant_type=password&username=${encodeURIComponent(data.username)}&password=${encodeURIComponent(data.password)}&client_id=admin_frontend&audience=back_office_api&scope=offline_access`,
  });

  const status = response.status;

  const result = await response.json();

  if (status !== 200) {
    throw new Error('Invalid username or password');
  }

  return result;
};

const fetchCurrentUser = async (accessToken: string): Promise<IUser> => {
  const franchisor = store.getState().auth.franchisor;
  const response = await fetch(`${Config.SERVER_URL}/api/account`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Franchisor-Id': franchisor ? String(franchisor.data.id) : '',
      'Authorization': 'Bearer ' + accessToken
    }
  });

  const status = response.status;

  const result = await response.json();

  if (status !== 200) {
    throw new Error('Invalid access token');
  }

  return result;
};

function* loginSaga(action: Action<ILoginParam>) {
  try {
    const tokenResult: ILoginResponse = yield fetchLoginAPI(action.payload);
    const userResult: IUser = yield fetchCurrentUser(tokenResult.access_token);
    // yield new Promise(resolve => setTimeout(resolve, 2000));
    const now = Date.now();
    yield put(loginSuccess({
      expireAt: now + tokenResult.expires_in,
      issuedAt: now,
      refreshToken: tokenResult.refresh_token,
      token: tokenResult.access_token,
      tokenType: tokenResult.token_type,
      user: userResult,
    }));

    analytics.setUser({ userId: userResult.id });
    analytics.event('login');

    yield put(fetchCenters({ forceFetch: true }));
    yield put(fetchTerms({ forceFetch: true }));

    const redirectUrl: any = QueryString.parse(history.location.search).redirect || '/';

    history.push(redirectUrl);

    yield put(setIsAuthenticating(false));
    yield put(reloadLanguages());
  } catch (err) {
    yield put(loginFail());
    yield put(setGlobalMessageBox({
      title: 'Login failed',
      message: err.message,
      type: 'error',
    }));
  }
}

export default [
  takeLatest(login.toString(), loginSaga),
];
