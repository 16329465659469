import { SVGImage } from 'components';
import React, { Component } from 'react';
import { RouteChildrenProps, withRouter } from 'react-router';
import { IFetchFranchisorParam } from 'types';

export interface IDispatchProps {
  fetchFranchisor: (params: IFetchFranchisorParam) => void;
}

export interface IStateProps {
  isFetchingFranchisor: boolean;
  errorMessage: string;
}

interface IParams {
  organisation: string;
}

interface IState {
  organization: string;
}

class LoginOrganizationComponent extends Component<IDispatchProps & IStateProps & RouteChildrenProps<IParams>, IState> {
  state = {
    organization: '',
  };

  componentDidMount() {
    if (this.props.match.params.organisation) {
      this.setState({
        organization: this.props.match.params.organisation,
      });
    }
  }

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    this.props.fetchFranchisor({
      domain: this.state.organization,
    });
  }

  handleChangeOrganization = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      organization: e.target.value,
    });
  }

  render() {
    const { isFetchingFranchisor, errorMessage } = this.props;

    return (
      <div className="vh-100 vw-100 d-flex align-items-center justify-content-center bg-primary">
        <div className="card">
          <div className="card-body text-center">
            <SVGImage src={require('assets/images/logo-blue.svg')} className="mt-3 mb-3" />
          </div>
          <h4 className="text-primary ml-3">Login to your organisation</h4>
          <hr className="m-0" />
          <div className="card-body">
            <form onSubmit={this.handleSubmit}>
              <div className="d-flex flex-row align-items-center">
                <span>bookingautopilot.com/</span>
                <input required className="form-control" value={this.state.organization} onChange={this.handleChangeOrganization} />
              </div>
              <p className="text-danger mt-2 text-right">{errorMessage}</p>
              <div className="form-group mb-0 text-center d-flex align-items-center flex-column">
                <button type="submit" className="btn btn-primary" disabled={isFetchingFranchisor}>
                  {!isFetchingFranchisor && 'Sign in'}
                  {isFetchingFranchisor && (
                    <>
                      <span>Sign in...&nbsp;</span>
                      <div className="spinner-border spinner-border-sm"></div>
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginOrganizationComponent);
