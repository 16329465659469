import React from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './checkout-form.component';
import { IMakePayment, IPayment, IPaymentMethod } from '@app/types';

interface IProps {
  payment: IPayment;
  selectedPaymentMethod: IPaymentMethod;
  currency: string;
  onMakePayment: (payment: IMakePayment) => void;
}

const StripeProviderComponent: React.FC<IProps> = (props) => {
  const paymentMethod = props.selectedPaymentMethod;
  const apiKey =
    paymentMethod && paymentMethod.configuration
      ? paymentMethod.configuration.publishableApiKey
      : "";
  const connectedAccountId =
    paymentMethod && paymentMethod.configuration
      ? paymentMethod.configuration.connectedAccountId
      : "";
  const stripePaymentMethodConfiguration =
    paymentMethod && paymentMethod.configuration ?
      paymentMethod.configuration.paymentMethodConfigurationId
      : "";

  return apiKey ? <Elements
    stripe={loadStripe(apiKey, {
      stripeAccount: connectedAccountId
    })}
    options={{
      mode: 'payment',
      currency: props.currency.toLowerCase(),
      amount: Math.round(props.payment.total * 100),
      payment_method_configuration: stripePaymentMethodConfiguration
    }}
  >
    <CheckoutForm payment={props.payment} onMakePayment={props.onMakePayment} />
  </Elements> : null;
}

export default StripeProviderComponent;
